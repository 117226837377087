import { LoaderFunctionArgs } from "react-router-dom";
import { appRoutes } from "../../../constants/appRoutes";
import { GetInterviewDetailsParamsType } from "../../../apis/getInterviewsList";
import { candidateRecordingsRoute } from "./candidateRecordings";
import { createInterviewRoute } from "./createInterview"

export interface IInterviewManagementLoader {
  params: GetInterviewDetailsParamsType;
}

export const interviewManagementRoute = {
  id: appRoutes.interviewManagement.id,
  path: appRoutes.interviewManagement.path,
  async loader({ request }: LoaderFunctionArgs) {
    let { loader } = await import(
      "../../../pages/Dashboard/InterviewManagement/loader"
    );
    return loader({ request } as LoaderFunctionArgs);
  },
  children: [
    {
      index: true,
      lazy: () => import("../../../pages/Dashboard/InterviewManagement"),
    },
    candidateRecordingsRoute,
    createInterviewRoute
  ],
  // ----- EXAMPLE FOR IGNORING RELOAD FOR SPECIFIC KEYS IN SEARCH PARAMS -----
  // shouldRevalidate: ({ currentUrl, nextUrl }: Parameters<ShouldRevalidateFunction>[0]) => {
  //   return shouldRevalidateWithSearchParams(currentUrl, nextUrl, ['status'])
  // }
};
