import { appRoutes } from "../../../constants/appRoutes";
import { LoaderFunctionArgs } from "react-router-dom";

export type CandidateUpdateParamsType = { params: { candidateId: string } };

export const createCandidateRoute = {
  path: appRoutes.createCandidate.path,
  async loader({ params }: LoaderFunctionArgs) {
    const { loader } = await import(
      "../../../pages/Dashboard/CreateCandidate/loader"
    );
    return loader({ params } as CandidateUpdateParamsType);
  },
  lazy: () => import("../../../pages/Dashboard/CreateCandidate"),
};
