import { LoaderFunctionArgs } from "react-router-dom";
import { appRoutes } from "../../../constants/appRoutes";


export interface IBulkInterviewScheduleLoader {
  params: { jobPostId: string };
}

export const bulkInterviewCreateRoute = {
  path: appRoutes.bulkInterviewCreate.path,
  async loader({ params }: LoaderFunctionArgs) {
    const { loader } = await import(
      "../../../pages/Dashboard/CreateBulkInterview/loader"
    );

    return loader({ params } as IBulkInterviewScheduleLoader);
  },
  lazy: () => import("../../../pages/Dashboard/CreateBulkInterview"),
};
