import { useRouteError } from "react-router-dom";
import { warningGrey } from "../../../assets/icons";
import ss from "./styles.module.css";

export const ErrorBoundary = () => {
    let error = useRouteError();
    console.error("thrown error",error);

    return (
      <div className={ss.container}>
        <img src={warningGrey} alt="warning_icon" />
        <div className={ss["text-container"]}>
          <h3>Oops something went wrong!</h3>
          <label>Please come back again later</label>
        </div>
      </div>
    );
}