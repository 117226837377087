import { IAuthGuard } from "../utils/authGuard";
import { constants } from "./constants";

const { userRoles } = constants;

export interface IAppRoutesConfigValue {
  id?: string;
  path: string;
  relativePath?: string;
  authConfig?: IAuthGuard & {
    redirectUrl?: string | Function;
  };
}

export const appRoutes = {
  root: {
    path: "/",
  },
  login: {
    path: "/login",
  },
  error: {
    path: "/error",
  },
  get dashboard() {
    return {
      path: "/dashboard",
      authConfig: {
        roles: [userRoles.HR],
        redirectUrl: this.root.path,
      },
    };
  },
  sendCandidateInvite: {
    path: "/dashboard/send-candidate-invite",
    relativePath: "send-candidate-invite",
    authConfig: {
      roles: [userRoles.HR],
    },
  },
  interviewManagement: {
    id: "interviews",
    path: "/dashboard/interviews",
    relativePath: "interviews",
    authConfig: {
      roles: [userRoles.HR],
    },
  },
  candidateRecordings: {
    path: "/dashboard/interviews/candidate-recordings/:interviewId",
    relativePath: "candidate-recordings",
    authConfig: {
      roles: [userRoles.HR],
    },
  },
  jobPostManagement: {
    id: "job-posts",
    path: "/dashboard/job-posts",
    relativePath: "job-post",
    authConfig: {
      roles: [userRoles.HR],
    },
  },
  createJobDescription: {
    path: "/dashboard/job-posts/create",
    relativePath: "create",
    authConfig: {
      roles: [userRoles.HR],
    },
  },
  jobPostDetail: {
    id: "job-post-detail",
    path: "/dashboard/job-posts/:jobPostId",
    relativePath: ":jobPostId",
    authConfig: {
      roles: [userRoles.HR],
    },
  },
  bulkInterviewCreate: {
    path: "/dashboard/job-posts/:jobPostId/bulk-schedule-interview",
    relativePath: "candidates-bulk-upload",
    authConfig: { roles: [userRoles.HR] },
  },
  interviewDetails: {
    path: "/dashboard/interviews/:interviewId",
    relativePath: ":interviewId",
    authConfig: {
      roles: [userRoles.HR],
    },
  },
  createCandidate: {
    path: "/dashboard/candidates/create-candidate",
    relativePath: "candidates/create-candidate",
    authConfig: { roles: [userRoles.HR] },
  },
  candidateProfile: {
    id: "candidateProfile",
    path: "/dashboard/candidates/details/:candidateId",
    relativePath: "details/:candidateId",
    authConfig: {
      roles: [userRoles.HR],
    },
  },
  candidateManagement: {
    id: "candidates",
    path: "/dashboard/candidates",
    relativePath: "candidates",
    authConfig: { roles: [userRoles.HR] },
  },
  candidateAllRecording: {
    path: "/dashboard/candidates/details/:candidateId/all-recordings",
    relativePath: "details/:candidateId/all-recordings",
    authConfig: { roles: [userRoles.HR] },
  },
  questions: {
    id: "questions",
    path: "/dashboard/questions",
    relativePath: "questions",
    authConfig: { roles: [userRoles.HR] },
  },
  updateCandidate: {
    id: "updateCandidate",
    path: "/dashboard/candidates/update-candidate/:candidateId",
    relativePath: "details/:candidateId/update-candidate",
    authConfig: { roles: [userRoles.HR] },
  },
} satisfies Record<string, IAppRoutesConfigValue>;
