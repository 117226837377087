import { appRoutes } from "../../../constants/appRoutes";

export const createJobPostRoute = {
  path: appRoutes.createJobDescription.path,
  async loader() {
    const { loader } = await import(
      "../../../pages/Dashboard/CreateJobPost/loader"
    );
    return loader();
  },
  lazy: () => import("../../../pages/Dashboard/CreateJobPost"),
};