import { LoaderFunctionArgs } from "react-router-dom";
import { candidateAllRecordingRoute } from "./candidateAllRecording";
import { appRoutes } from "../../../constants/appRoutes";


export interface ICandidateDetailsLoader {
    params: { candidateId: string };
}

export const candidateDetailsRoute = {
    id: appRoutes.candidateProfile.id,
    path: appRoutes.candidateProfile.path,
    async loader({ params }: LoaderFunctionArgs) {
        const { loader } = await import(
            "../../../pages/Dashboard/CandidateProfile/loader"
        );
        return loader({ params } as ICandidateDetailsLoader);
    },
    children: [
        {
          index: true,
          lazy: () => import("../../../pages/Dashboard/CandidateProfile"),
        },
        candidateAllRecordingRoute,
      ],
};