import { LoaderFunctionArgs } from "react-router-dom";
import { appRoutes } from "../../../constants/appRoutes";
import { candidateDetailsRoute } from "./candidateProfile";
import { createCandidateRoute } from "./createCandidate";
import { updateCandidateRoute } from "./updateCandidate";

export const candidateManagementRoute = {
  id: appRoutes.candidateManagement.id,
  path: appRoutes.candidateManagement.path,
  async loader({ request }: LoaderFunctionArgs) {
    let { loader } = await import(
      "../../../pages/Dashboard/CandidateManagement/loader"
    );

    return loader({ request } as LoaderFunctionArgs);
  },
  children: [
    {
      index: true,
      lazy: () => import("../../../pages/Dashboard/CandidateManagement"),
    },
    candidateDetailsRoute,
    createCandidateRoute,
    updateCandidateRoute
  ],
};
