import { LoaderFunctionArgs } from "react-router-dom";
import { appRoutes } from "../../../constants/appRoutes";
import { jobPostDetailRoute } from "./jobPostDetail";
import { createJobPostRoute } from "./createJobPost";

export const jobPostManagementRoute = {
  id: appRoutes.jobPostManagement.id,
  path: appRoutes.jobPostManagement.path,
  async loader({ request }: LoaderFunctionArgs) {
    let { loader } = await import(
      "../../../pages/Dashboard/JobPostManagement/loader"
    );
    return loader({ request } as LoaderFunctionArgs);
  },
  children: [
    {
      index: true,
      lazy: () => import("../../../pages/Dashboard/JobPostManagement"),
    },
    createJobPostRoute,
    jobPostDetailRoute,
  ]
};
