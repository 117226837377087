import { appRoutes } from "../../../constants/appRoutes";
import { interviewManagementRoute } from "./interviewManagement";
import { candidateRecordingsRoute } from "./candidateRecordings";
import { jobPostManagementRoute } from "./jobPostManagement";
import { candidateManagementRoute } from "./candidateManagement";

export const dashboardRoutes = {
  path: appRoutes.dashboard.path,
  lazy: () => import("../../../pages/Dashboard"),
  children: [
    // INTERVIEW MANAGEMENT
    interviewManagementRoute,
    // INTERVIEW DETAILS
    candidateRecordingsRoute,
    //JOB POST MANAGEMENT
    jobPostManagementRoute,
    //CANDIDATE MANAGEMENT,
    candidateManagementRoute,
  ],
};