import { LoaderFunctionArgs } from "react-router-dom";
import { appRoutes } from "../../../constants/appRoutes";
import { bulkInterviewCreateRoute } from "./candidatesBulkUpload";

export interface IJobPostLoader {
    params: { jobPostId: string };
}

export const jobPostDetailRoute = {
    id: appRoutes.jobPostDetail.id,
    path: appRoutes.jobPostDetail.path,
    async loader({ params }: LoaderFunctionArgs) {
        const { loader } = await import(
            "../../../pages/Dashboard/JobPostDetail/loader"
        );
        return loader({ params } as IJobPostLoader);
    },
    children: [
        {
            index: true,
            lazy: () => import("../../../pages/Dashboard/JobPostDetail"),
        },
        bulkInterviewCreateRoute,

    ]
};

