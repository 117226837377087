import { createBrowserRouter, redirect } from "react-router-dom";
import { ErrorBoundary } from "../pages/Root/ErrorBoundary";
import { appRoutes } from "../constants/appRoutes";
import { routes } from "./routes";
import { injectAuthGuard } from "./helper";

injectAuthGuard(routes);

export const router = createBrowserRouter([
  {
    path: appRoutes.root.path,
    lazy: () => import("../pages/Root"),
    ErrorBoundary: ErrorBoundary,
    children: [
      routes.login,
      // DASHBOARD - UPLOAD RESUME, CANDIDATE MANAGEMENT, INTERVIEW MANAGEMENT, JOB POST MANAGEMEMT
      routes.dashboard,
      routes.error
    ],
  },
  {
    path: '*',
    loader: () => redirect(appRoutes.root.path)
  },
]);
