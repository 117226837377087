import { LoaderFunctionArgs } from "react-router-dom";
import { appRoutes } from "../../../constants/appRoutes";

export interface IInterviewDetailLoader {
  params: { interviewId: string };
}

export const candidateRecordingsRoute = {
  path: appRoutes.candidateRecordings.path,
  async loader({ params }: LoaderFunctionArgs) {
    const { loader } = await import(
      "../../../pages/Dashboard/CandidateRecordings/loader"
    );
    return loader({ params } as IInterviewDetailLoader);
  },
  lazy: () => import("../../../pages/Dashboard/CandidateRecordings"),
};
