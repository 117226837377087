import { LoaderFunctionArgs } from "react-router-dom";
import { appRoutes } from "../../constants/appRoutes";

export const loginRoute = {
    index: true,
    path: appRoutes.root.path,
    async loader(params: LoaderFunctionArgs){
        let {loader} = await import("../../pages/Login/loader");
        return loader(params);
    },
    lazy: () => import("../../pages/Login"),
}