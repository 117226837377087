import { LoaderFunctionArgs } from "react-router-dom";
import { appRoutes } from "../../../constants/appRoutes";
import { ICandidateDetailsLoader } from "./candidateProfile";


export const candidateAllRecordingRoute = {
    path: appRoutes.candidateAllRecording.path,
    async loader({ params }: LoaderFunctionArgs) {
        const { loader } = await import(
            "../../../pages/Dashboard//CandidateAllRecordings/loader"
        );
        return loader({ params } as ICandidateDetailsLoader);
    },
    lazy: () => import("../../../pages/Dashboard/CandidateAllRecordings"),
};